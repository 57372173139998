import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import CourseCard from './CourseCard';
import Map from './Map';

function CountyCoursesPage() {
  const { county } = useParams();
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hoveredCourseId, setHoveredCourseId] = useState(null);

  useEffect(() => {
    fetchCoursesByCounty();
  }, [county]);

  async function fetchCoursesByCounty() {
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('golf_courses')
        .select('*')
        .or(`location.ilike.%${county}%,name.ilike.%${county}%`);

      if (error) throw error;

      const processedData = await Promise.all(data.map(async (course) => {
        const coordinates = await getCoordinates(course.location);
        return {
          ...course,
          latitude: coordinates ? coordinates.lat : null,
          longitude: coordinates ? coordinates.lng : null
        };
      }));

      setCourses(processedData);
    } catch (error) {
      console.error('Error fetching courses:', error.message);
    } finally {
      setLoading(false);
    }
  }

  function extractPostcode(address) {
    const postcodeRegex = /[A-Z]{1,2}[0-9][0-9A-Z]?\s?[0-9][A-Z]{2}/i;
    const match = address.match(postcodeRegex);
    return match ? match[0].replace(/\s/g, '') : null;
  }

  async function getCoordinates(address) {
    const postcode = extractPostcode(address);
    if (!postcode) {
      console.error('No valid postcode found in address:', address);
      return null;
    }
    
    console.log('Extracted postcode:', postcode);

    try {
      const response = await fetch(`https://api.postcodes.io/postcodes/${postcode}`);
      const data = await response.json();
      if (data.status === 200) {
        return { lat: data.result.latitude, lng: data.result.longitude };
      }
      console.error('Error fetching coordinates for postcode:', postcode, data.status);
      return null;
    } catch (error) {
      console.error('Error fetching coordinates:', error);
      return null;
    }
  }

  if (loading) {
    return <div className="container mx-auto px-4 py-8">Loading...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Golf Courses in {county}</h1>
      {courses.length === 0 ? (
        <p>No courses found in {county}.</p>
      ) : (
        <div className="flex flex-col lg:flex-row">
          <div className="lg:w-2/3 pr-0 lg:pr-8">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {courses.map(course => (
                <Link 
                  key={course.id} 
                  to={`/course/${course.id}`}
                  onMouseEnter={() => setHoveredCourseId(course.id)}
                  onMouseLeave={() => setHoveredCourseId(null)}
                >
                  <CourseCard course={course} />
                </Link>
              ))}
            </div>
          </div>
          <div className="lg:w-1/3 mt-8 lg:mt-0">
            <Map 
              courses={courses} 
              hoveredCourseId={hoveredCourseId}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default CountyCoursesPage;