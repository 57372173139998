import React, { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import CourseCard from './CourseCard';

function SearchResults() {
  const [searchParams] = useSearchParams();
  const query = searchParams.get('q') || '';
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function searchCourses() {
      setIsLoading(true);
      try {
        const { data, error } = await supabase
          .from('golf_courses')
          .select('*')
          .or(`name.ilike.%${query}%,location.ilike.%${query}%,type.ilike.%${query}%`)
          .limit(20);

        if (error) throw error;
        setFilteredCourses(data);
      } catch (error) {
        console.error('Error searching courses:', error.message);
        setFilteredCourses([]);
      } finally {
        setIsLoading(false);
      }
    }

    searchCourses();
  }, [query]);

  if (isLoading) {
    return <div className="container mx-auto px-4 py-8">Loading...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold mb-4">Search Results for "{query}"</h2>
      {filteredCourses.length === 0 ? (
        <p>No courses found matching your search.</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredCourses.map(course => (
            <Link key={course.id} to={`/course/${course.id}`}>
              <CourseCard course={course} />
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}

export default SearchResults;