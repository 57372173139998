import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import HomePage from './components/HomePage';
import CoursePage from './components/CoursePage';
import SearchResults from './components/SearchResults';
import CountyCoursesPage from './components/CountyCoursesPage';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/course/:id" element={<CoursePage />} />
          <Route path="/search" element={<SearchResults />} />
          <Route path="/county/:county" element={<CountyCoursesPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;