import React, { useState, useEffect, useCallback, useRef, Suspense, lazy } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import useIsMobile from '../hooks/useIsMobile';
import CountyLinks from './CountyLinks';

// Lazy load components
const CourseCard = lazy(() => import('./CourseCard'));
const Map = lazy(() => import('./Map'));

function HomePage() {
  const [courses, setCourses] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [hoveredCourseId, setHoveredCourseId] = useState(null);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();
  const isMobile = useIsMobile();

  const COURSES_PER_PAGE = 9;

  useEffect(() => {
    fetchCourses();
  }, []);

  async function fetchCourses() {
    if (loading || !hasMore) return;
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('golf_courses')
        .select('*')
        .range(page * COURSES_PER_PAGE, (page + 1) * COURSES_PER_PAGE - 1);
      
      if (error) throw error;
      
      const processedData = await Promise.all(data.map(async (course) => {
        const coordinates = await getCoordinates(course.location);
        return {
          ...course,
          latitude: coordinates ? coordinates.lat : null,
          longitude: coordinates ? coordinates.lng : null
        };
      }));
      
      setCourses(prevCourses => [...prevCourses, ...processedData]);
      setPage(prevPage => prevPage + 1);
      setHasMore(data.length === COURSES_PER_PAGE);
    } catch (error) {
      console.error('Error fetching courses:', error.message);
    } finally {
      setLoading(false);
    }
  }

  function extractPostcode(address) {
    const postcodeRegex = /[A-Z]{1,2}[0-9][0-9A-Z]?\s?[0-9][A-Z]{2}/i;
    const match = address.match(postcodeRegex);
    return match ? match[0].replace(/\s/g, '') : null;
  }

  async function getCoordinates(address) {
    const postcode = extractPostcode(address);
    if (!postcode) {
      console.error('No valid postcode found in address:', address);
      return null;
    }
    
    try {
      const response = await fetch(`https://api.postcodes.io/postcodes/${postcode}`);
      const data = await response.json();
      if (data.status === 200) {
        return { lat: data.result.latitude, lng: data.result.longitude };
      }
      console.error('Error fetching coordinates for postcode:', postcode, data.status);
      return null;
    } catch (error) {
      console.error('Error fetching coordinates:', error);
      return null;
    }
  }

  const lastCourseElementRef = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        fetchCourses();
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  const filteredCourses = activeFilters.length > 0
    ? courses.filter(course => activeFilters.includes(course.type))
    : courses;

  return (
    <div className="min-h-screen bg-gradient-to-b from-green-50 to-green-100">
      <CountyLinks />  {/* Add CountyLinks component here */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex flex-col lg:flex-row">
          <div className="lg:w-2/3 pr-0 lg:pr-8">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {filteredCourses.map((course, index) => (
                <Link 
                  key={course.id} 
                  to={`/course/${course.id}`}
                  onMouseEnter={() => setHoveredCourseId(course.id)}
                  onMouseLeave={() => setHoveredCourseId(null)}
                  ref={index === filteredCourses.length - 1 ? lastCourseElementRef : null}
                >
                  <Suspense fallback={<div>Loading Course Card...</div>}>
                    <CourseCard course={course} />
                  </Suspense>
                </Link>
              ))}
            </div>
            {loading && <p className="text-center mt-4">Loading more courses...</p>}
          </div>
          {!isMobile && (
            <div className="lg:w-1/3 mt-8 lg:mt-0">
              <Suspense fallback={<div>Loading Map...</div>}>
                <Map 
                  courses={filteredCourses} 
                  hoveredCourseId={hoveredCourseId}
                />
              </Suspense>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default HomePage;