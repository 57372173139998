import React from 'react';
import { Link } from 'react-router-dom';

const CountyLinks = () => {
  const counties = [
    "Kent",
    "West Sussex",
    "East Sussex",
    "Hampshire",
    "Dorset"
  ];

  return (
    <div className="flex flex-wrap gap-4 p-4 justify-center">
      {counties.map((county) => (
        <Link
          key={county}
          to={`/county/${encodeURIComponent(county)}`}
          className="px-4 py-2 bg-green-600 text-white rounded-full hover:bg-green-700 transition-colors duration-300"
        >
          {county}
        </Link>
      ))}
    </div>
  );
};

export default CountyLinks;