import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { supabase } from '../supabaseClient';
import { MapPin, Flag, DollarSign, Star } from 'lucide-react';
import FormattedCourseInfo from './FormattedCourseInfo';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CoursePage = () => {
  const { id } = useParams();
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const { data, error } = await supabase
          .from('golf_courses')
          .select('*')
          .eq('id', id)
          .single();

        if (error) throw error;

        console.log('Fetched course data:', data);
        setCourse(data);
      } catch (error) {
        setError('Failed to fetch course data');
        console.error('Error fetching course:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCourse();
    window.scrollTo(0, 0);
  }, [id]);

  if (loading) return <div className="flex items-center justify-center h-screen text-xl">Loading...</div>;
  if (error) return <div className="flex items-center justify-center h-screen text-xl">Error: {error}</div>;
  if (!course) return <div className="flex items-center justify-center h-screen text-xl">Course not found</div>;

  const contactInfo = JSON.parse(course.contact_information || '{}');
  const facilities = Array.isArray(course.facilities) ? course.facilities : course.facilities?.split(',').map(item => item.trim()) || [];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // Remove autoplay settings
  };

  const allImages = [course.main_image_url, ...(course.additional_images || [])].filter(Boolean);

  return (
    <div className="min-h-screen bg-gradient-to-b from-green-50 to-green-100">
      <Helmet>
        <title>{`${course.name} - Golf Course Details`}</title>
        <meta name="description" content={course.seo_overview} />
      </Helmet>

      <main className="max-w-4xl mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold text-green-800 mb-6">{course.name}</h1>

        <div className="mb-8 rounded-lg overflow-hidden shadow-lg">
          {allImages.length > 0 ? (
            <Slider {...sliderSettings}>
              {allImages.map((image, index) => (
                <div key={index}>
                  <img 
                    src={image} 
                    alt={`${course.name} golf course - image ${index + 1}`} 
                    className="w-full h-96 object-cover" // Increased height to h-96
                    onError={(e) => {
                      console.error(`Error loading image: ${image}`);
                      e.target.src = '/default-course-image.jpg';
                    }}
                  />
                </div>
              ))}
            </Slider>
          ) : (
            <img 
              src="/default-course-image.jpg" 
              alt={`${course.name} golf course`} 
              className="w-full h-96 object-cover" // Increased height to h-96
            />
          )}
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="text-2xl font-semibold text-green-700 mb-4">Course Information</h2>
          <div className="space-y-4">
            <div className="flex items-center">
              <MapPin className="w-5 h-5 text-green-600 mr-2 flex-shrink-0" />
              <span className="text-gray-700">{course.location}</span>
            </div>
            <div className="flex items-center">
              <Flag className="w-5 h-5 text-green-600 mr-2 flex-shrink-0" />
              <span className="text-gray-700">{course.par} par</span>
            </div>
            <div className="flex items-center">
              <DollarSign className="w-5 h-5 text-green-600 mr-2 flex-shrink-0" />
              <span className="text-gray-700">{course.pricing_estimate}</span>
            </div>
            <div className="flex items-start">
              <Star className="w-5 h-5 text-yellow-400 mr-2 flex-shrink-0 mt-1" />
              <span className="text-gray-700">{course.user_reviews}</span>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="text-2xl font-semibold text-green-700 mb-4">About the Course</h2>
          <p className="text-gray-700 mb-4">{course.seo_overview}</p>
          <ul className="list-disc list-inside text-gray-700">
            <li>Number of Holes: {course.number_of_holes}</li>
            <li>Par: {course.par}</li>
            <li>Length: {course.length_in_yards} yards</li>
            {facilities.map((facility, index) => (
              <li key={index}>{facility}</li>
            ))}
          </ul>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="text-2xl font-semibold text-green-700 mb-4">Standout Holes and Features</h2>
          <p className="text-gray-700">{course.standout_holes_or_features}</p>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <div className="formatted-content">
            <FormattedCourseInfo content={course.seo_detailed_content} />
          </div>
        </div>

        <div className="bg-green-700 text-white rounded-lg shadow-md p-6">
          <h2 className="text-2xl font-semibold mb-4">Contact Information</h2>
          <p className="mb-2">Phone: {contactInfo.phone_number || 'N/A'}</p>
          {contactInfo.email && <p className="mb-4">Email: {contactInfo.email}</p>}
          <button className="bg-white text-green-700 py-2 px-4 rounded hover:bg-green-100 transition duration-300">
            Book Now
          </button>
        </div>
      </main>
    </div>
  );
};

export default CoursePage;