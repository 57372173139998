import React from 'react';

const FormattedCourseInfo = ({ content }) => {
  const formatText = (text) => {
    // Split the text into lines
    const lines = text.split('\\n').map(line => line.trim());
    
    let formattedContent = [];
    let currentParagraph = [];

    lines.forEach((line, index) => {
      if (line.startsWith('###')) {
        // If we have a current paragraph, add it to the formatted content
        if (currentParagraph.length > 0) {
          formattedContent.push(<p key={`p-${index}`} className="mb-4">{currentParagraph}</p>);
          currentParagraph = [];
        }
        // Add the header
        const title = line.replace(/^###\s*/, '').trim();
        formattedContent.push(
          <h3 key={`h3-${index}`} className="text-2xl font-semibold text-green-700 mt-8 mb-4">
            {title}
          </h3>
        );
      } else if (line.trim() !== '') {
        // Add non-empty lines to the current paragraph
        currentParagraph.push(line);
        if (index < lines.length - 1) {
          currentParagraph.push(<br key={`br-${index}`} />);
        }
      } else if (currentParagraph.length > 0) {
        // Empty line, end the current paragraph
        formattedContent.push(<p key={`p-${index}`} className="mb-4">{currentParagraph}</p>);
        currentParagraph = [];
      }
    });

    // Add any remaining paragraph
    if (currentParagraph.length > 0) {
      formattedContent.push(<p key="p-last" className="mb-4">{currentParagraph}</p>);
    }

    return formattedContent;
  };

  return (
    <div className="text-gray-700 prose prose-green max-w-none">
      {formatText(content)}
    </div>
  );
};

export default FormattedCourseInfo;