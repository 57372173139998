import React from 'react';

function extractTownAndCounty(address) {
  if (!address) return 'Location not available';
  
  const regex = /,\s*([^,]+),\s*([^,]+),\s*[A-Z]{1,2}[0-9][0-9A-Z]?\s*[0-9][A-Z]{2}/;
  const match = address.match(regex);
  if (match) {
    return `${match[1]}, ${match[2]}`;
  }
  return address; // Return the full address if regex doesn't match
}

function CourseCard({ course }) {
  const location = course?.location ? extractTownAndCounty(course.location) : 'Location not available';

  return (
    <div className="border rounded-lg overflow-hidden hover:shadow-lg transition-shadow">
      <img 
        src={course?.main_image_url || '/default-course-image.jpg'} 
        alt={course?.name || 'Course image'} 
        className="w-full h-48 object-cover"
      />
      <div className="p-4">
        <h3 className="text-l font-bold">{course?.name || 'Course name not available'}</h3>
        <p className="text-sm text-gray-600 mt-2">{location}</p>
      </div>
    </div>
  );
}

function calculateAverageRating(reviews) {
  if (!reviews || reviews.length === 0) return 'N/A';
  
  try {
    const parsedReviews = typeof reviews === 'string' ? JSON.parse(reviews) : reviews;
    
    if (!Array.isArray(parsedReviews)) return 'N/A';
    
    const sum = parsedReviews.reduce((acc, review) => {
      const rating = typeof review === 'object' ? review.rating : review;
      return acc + (typeof rating === 'number' ? rating : 0);
    }, 0);
    
    return (sum / parsedReviews.length).toFixed(1);
  } catch (error) {
    console.error('Error calculating average rating:', error);
    return 'N/A';
  }
}

export default CourseCard;