import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

function Map({ courses, hoveredCourseId }) {
  // Center of South East England (approximate)
  const defaultCenter = [51.2, 0.5];
  const defaultZoom = 8; // Zoomed out to show more of South East England

  const validCourses = courses.filter(course => 
    course.latitude && course.longitude && 
    !isNaN(course.latitude) && !isNaN(course.longitude)
  );

  return (
    <MapContainer 
      center={defaultCenter} 
      zoom={defaultZoom} 
      style={{ height: '600px', width: '100%' }} // Increased height
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {validCourses.map((course) => (
        <Marker 
          key={course.id} 
          position={[course.latitude, course.longitude]}
          opacity={hoveredCourseId === course.id ? 1 : 0.5}
        >
          <Popup>
            <h3>{course.name}</h3>
            <p>{course.location}</p>
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
}

export default Map;